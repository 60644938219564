import React, { useEffect, useLayoutEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import {
  PageContainer,
  ContentContainer,
  Content,
  Text,
  Title,
  UpcommingTreksContent,
  Subtitle,
  TrekContainer,
  ButtonStyled,
  DescriptionContainer,
  DescriptionBox,
  Description,
  TrekContent,
  DescriptionSmall,
  TextBold,
  StyledList,
  StepsContainer,
  TextSteps,
} from './Trekking.styled';
// import { Newsletter } from '../../home/Newsletter';
import { useTranslation } from 'react-i18next';
import { SignParticipant } from './SignParticipant';
import supabase from '../../../../config/supabaseClient';

interface Trek {
  id: number;
  date: string;
  time: string;
  distance: string;
  difficultyLevel: string;
  place: string;
  maxParticipants: number;
  availableSpots: number;
}
interface Participant {
  id: number;
  status: string;
}

export const Trekking = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    dayjs.locale(i18n.language === 'pl' ? 'pl' : 'en');
  }, [i18n.language]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [treks, setTreks] = useState<Trek[]>([]);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [confirmedCount, setConfirmedCount] = useState(0);
  const [fetchError, setFetchError] = useState<string | null>(null);

  const [openForms, setOpenForms] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    const fetchTreks = async () => {
      const { data: trekData, error: trekError } = await supabase
        .from('treks')
        .select('*')
        .order('id', { ascending: true });

      if (trekError) {
        setFetchError('Could not fetch treks: ' + trekError.message);
        console.error('Error details:', trekError);
      } else {
        setTreks(trekData || []);
      }
    };

    fetchTreks();
  }, []);

  useEffect(() => {
    const fetchAmountOfParticipants = async () => {
      const { data: participantData, error: participantError } = await supabase
        .from('participants')
        .select('*');

      if (participantError) {
        setFetchError('Could not fetch treks: ' + participantError.message);
        console.error('Error details:', participantError);
      } else {
        setParticipants(participantData || []);
      }
    };

    fetchAmountOfParticipants();
  }, []);

  useEffect(() => {
    const count = participants.reduce((acc, participant) => {
      return acc + (participant.status === 'confirmed' ? 1 : 0);
    }, 0);
    console.log('setConfirmedCount(count);', setConfirmedCount(count));
    setConfirmedCount(count);
  }, [participants]);

  const toggleOpenSignInForm = (index: number) => {
    setOpenForms((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const isSignupEnabled = (trekDate: string): boolean => {
    const today = dayjs();
    const trekDay = dayjs(trekDate, 'DD.MM.YYYY');
    const twoWeeksBefore = trekDay.subtract(2, 'week');

    return today.isAfter(twoWeeksBefore) && today.isBefore(trekDay);
  };
  const getButtonLabel = (trekDate: string): string => {
    const today = dayjs();
    const trekDay = dayjs(trekDate, 'DD.MM.YYYY');
    const twoWeeksBefore = trekDay.subtract(2, 'week');

    if (!today.isAfter(twoWeeksBefore) || today.isAfter(trekDay)) {
      return t('button.sign-in-soon');
    } else if (confirmedCount >= 20) {
      console.log('confirmedCount', confirmedCount);
      return t('button.no-seats');
    }
    return t('button.sign-in');
  };

  console.log('treks', confirmedCount);

  const listSteps = t('trekking.steps', { returnObjects: true }) as string[];

  return (
    <PageContainer>
      <ContentContainer>
        <Content>
          <Title>{t('trekking.title')}</Title>
          <Text>{t('trekking.description1')}</Text>
          <Text>{t('trekking.description7')}</Text>

          <TextBold>{t('trekking.description14')}</TextBold>

          <Text>{t('trekking.description3')}</Text>
          <Text>{t('trekking.description15')}</Text>
        </Content>
      </ContentContainer>
      <StepsContainer>
        <Content>
          <TextBold>{t('trekking.question-title')}</TextBold>
          <TextSteps>{t('trekking.description2')}</TextSteps>

          <StyledList>
            {listSteps.map((item: string, index: number) => (
              <li key={index}>
                <TextSteps>{item}</TextSteps>
              </li>
            ))}
          </StyledList>
        </Content>
      </StepsContainer>
      <UpcommingTreksContent>
        <Subtitle>{t('trekking.subtitle')}</Subtitle>
        {fetchError && <p>{fetchError}</p>}
        {treks.map((trek: Trek, index) => (
          <>
            <TrekContainer key={index}>
              <TrekContent>
                <Text>
                  {dayjs(trek.date)
                    .locale(i18n.language)
                    .format('DD MMMM YYYY')}
                </Text>
                <Text>{trek.time}</Text>
                <Text>{trek.distance} km</Text>
                <Text>{trek.place}</Text>
                <Text>max {trek.maxParticipants} osób</Text>
              </TrekContent>
              <ButtonStyled
                onClick={() => toggleOpenSignInForm(index)}
                disabled={!isSignupEnabled(trek.date) || confirmedCount >= 20}
              >
                {getButtonLabel(trek.date)}
              </ButtonStyled>
            </TrekContainer>
            {openForms[index] && (
              <DescriptionContainer>
                <DescriptionBox>
                  <Description>{t('trekking.description4')}</Description>
                  <Description>{t('trekking.description5')}</Description>
                  <DescriptionSmall>
                    {t('trekking.description6')}
                  </DescriptionSmall>
                </DescriptionBox>
                <DescriptionBox>
                  <SignParticipant trekId={trek.id} />
                </DescriptionBox>
              </DescriptionContainer>
            )}
          </>
        ))}
      </UpcommingTreksContent>
      {/* <Newsletter /> */}
    </PageContainer>
  );
};
