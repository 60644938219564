import styled from 'styled-components';

export const FooterContainer = styled.div`
  flex-shrink: 0;

  color: white;
`;

export const FooterContent = styled.div`
  display: flex;
  height: 140px;
  justify-content: space-around;
  background-color: black;
  align-items: center;
  line-height: 30px;
  font-size: 14px;
  @media (max-width: 840px) {
    height: 160px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CreatedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  font-weight: 200;
  margin-right: 20px;
  margin-bottom: 20px;
`;

export const TextBold = styled.p`
  font-weight: 600;
`;

export const ButtonStyled = styled.button`
  font-weight: 300;
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #af9483;
    text-decoration: underline;
  }
  &:focus {
    color: #af9483;
    text-decoration: underline;
  }
`;

// export const StyledNewsletter = styled.iframe`
//   width: 580px;
//   height: 85vh;
//   border: none;
//   display: flex;
//   align-items: center;
//   margin: auto;
// `;
