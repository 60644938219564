import { Home } from '../components/pages/home/Home';
import { About } from '../components/pages/about/About';
import { Contact } from '../components/pages/contact/Contact';
import { Projects } from '../components/pages/projects/Projects';
import { Hiking } from '../components/pages/projects/hiking/Hiking';
import { Trekking } from '../components/pages/projects/trekking/Trekking';
import { Tatry } from '../components/pages/projects/hiking/Tatry';
import { Malaga } from '../components/pages/projects/hiking/Malaga';
import PrivacyPolicy from '../components/pages/PrivacyPolicy';
import EmailConfirmation from '../components/pages/EmailConfirmation';

interface Route {
  title: string;
  href: string;
  component: React.ComponentType;
  showInNav: boolean;
}

export const routes: Route[] = [
  {
    title: 'nav.confirmEmail',
    href: '/confirm',
    component: EmailConfirmation,
    showInNav: false,
  },
  {
    title: 'nav.home',
    href: '/',
    component: Home,
    showInNav: true,
  },
  {
    title: 'nav.about',
    href: '/about',
    component: About,
    showInNav: true,
  },
  {
    title: 'nav.trekking',
    href: '/projects/trekking',
    component: Trekking,
    showInNav: true,
  },
  {
    title: 'nav.hiking',
    href: '/projects/hiking',
    component: Hiking,
    showInNav: true,
  },
  {
    title: 'nav.projects',
    href: '/projects',
    component: Projects,
    showInNav: false,
  },
  {
    title: 'nav.contact',
    href: '/contact',
    component: Contact,
    showInNav: true,
  },

  {
    title: 'nav.tatry',
    href: '/projects/hiking/tatry',
    component: Tatry,
    showInNav: false,
  },
  {
    title: 'nav.malaga',
    href: '/projects/hiking/malaga',
    component: Malaga,
    showInNav: false,
  },
  {
    title: 'nav.policy',
    href: '/policy',
    component: PrivacyPolicy,
    showInNav: false,
  },
];
