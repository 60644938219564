import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: 40px auto;
  @media (max-width: 840px) {
    width: 90%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 4.5em;
  line-height: 1.3em;
  letter-spacing: 0.2rem;
  margin: 60px 15px 10px 15px;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 3em;
  }
  @media (max-width: 840px) {
    font-size: 2.5em;
  }
`;

export const BoldText = styled.p`
  font-weight: 800;
  line-height: 1.6em;
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 0.05rem;
  margin: 4px;
  padding-bottom: 20px;
  width: 100%;
  @media (max-width: 840px) {
    width: 100%;
    padding: 0px 15px 20px 15px;
    margin: 0;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  line-height: 1.6em;
  margin: 0 0 15px 0px;
  @media (max-width: 840px) {
    padding: 10px 0 15px 0;
    margin: 0;
  }
`;

export const Subtitle = styled.h2`
  text-align: start;
  font-weight: 900;
  font-size: 2em;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
  margin: 20px 0px 10px 0px;
  @media (max-width: 840px) {
    font-size: 1em;
    padding: 0 0 0 0;
  }
`;

export const StyledSubtitle = styled.h2`
  text-align: start;
  font-weight: 900;
  font-size: 1.5em;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
  margin: 10px 0px 10px 0px;

  @media (max-width: 840px) {
    font-size: 1em;
    margin: 10px 0 10px 0;
  }
`;

export const StyledList = styled.ul`
  margin: 0 0 15px 30px;
`;

export const SmallText = styled.p`
  font-weight: 400;
  line-height: 0.8em;
  font-size: 0.8em;
  letter-spacing: 0.02rem;
  margin: 45px 0px 15px 0px;
  @media (max-width: 840px) {
    width: 100%;
    padding: 0 15px;
    margin: 0;
  }
`;

export const StyledGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 16px;
  max-width: 100%;
`;

export const ImageWrapper = styled.div`
  width: auto
  height: 300px;
  background: #eee;
`;

export const CroppedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const StyledImage = styled.img`
  margin-bottom: 30px;
`;

export const ButtonStyled = styled.button`
  width: 50%;
  font-weight: 900;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 3px;
  box-shadow: 5px 5px 30px 1px rgba(0, 0, 0, 0.2);
  transition:
    background-color 0.1s ease,
    transform 0.1s ease;

  &:hover {
    background-color: #af9483;
  }
  &:focus {
    background-color: #af9483;
  }
  &:disabled {
    background-color: rgba(189, 189, 189, 0.7);
    color: #e5e5e5;
    box-shadow: none;
  }
`;

export const StyledTextCallToAction = styled.p`
  font-weight: 400;
  line-height: 1.6em;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const StyledCallToAction = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-bottom: 30px;
  margin-top: 10px;
`;
