import React from 'react';
import { StyledImg, StyledText } from './IconLink.styles';

interface IconListProps {
  href: string;
  imgSrc: string;
  altText: string;
  text?: string;
}

export const IconLink: React.FC<IconListProps> = ({
  href,
  imgSrc,
  altText,
  text,
}) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {text && <StyledText>{text}</StyledText>}
      <StyledImg src={imgSrc} alt={altText} className="h-6 w-auto" />
    </a>
  );
};
