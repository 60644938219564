import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  FormContainer,
  FormStyled,
  FieldStyled,
  NamesStyled,
  InputGroup,
  ErrorStyled,
  AdditionalInfoStyled,
} from './SignParticipant.styles';
import { ButtonStyled } from '../Projects.styles';
import { Notification } from '../../../shared/Notification';
import * as Yup from 'yup';
import axios from 'axios';

interface Values {
  firstName: string;
  email: string;
  trekId: number;
}

interface SignParticipantProps {
  trekId: number;
}

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Too short!')
    .max(30, 'Too long!')
    .required('Required!'),
  email: Yup.string().email('Invalid email!').required('Required'),
});

export const SignParticipant = ({ trekId }: SignParticipantProps) => {
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get('/.netlify/functions/generate-csrf-token')
      .then((response) => {
        const { csrfToken } = response.data;
        setCsrfToken(csrfToken);
      })
      .catch((error) => {
        console.error('Error fetching CSRF token:', error);
      });
  }, []);

  const submitForm = async (values: Values, formik: FormikHelpers<Values>) => {
    const { firstName, email } = values;

    const payload = {
      firstName,
      email,
      trekId,
    };

    try {
      const response = await axios.post('/.netlify/functions/submit-form', {
        payload,
        csrfToken,
      });
      const data = response.data;

      console.log('payload', payload);
      console.log('successfuly saved data:', data);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 5000);
      formik.resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage(t('message.error'));
      setShowErrorNotification(true);
      setTimeout(() => setShowErrorNotification(false), 5000);
    }
  };

  return (
    <FormContainer>
      <Formik
        initialValues={{
          firstName: '',
          email: '',
          trekId,
        }}
        validationSchema={SignupSchema}
        onSubmit={submitForm}
      >
        {(formik) => {
          const { t } = useTranslation();
          return (
            <FormStyled>
              <NamesStyled>
                <InputGroup>
                  <input type="hidden" name="csrfToken" value={csrfToken} />
                  <FieldStyled
                    id="firstName"
                    name="firstName"
                    placeholder={t('newsletter.first-name')}
                  />
                  <ErrorStyled name="firstName" component="div" />
                </InputGroup>
                <InputGroup>
                  <FieldStyled
                    id="email"
                    name="email"
                    placeholder={t('newsletter.email')}
                    type="email"
                  />
                  <ErrorStyled name="email" component="div" />
                </InputGroup>
              </NamesStyled>

              <AdditionalInfoStyled>
                {t('newsletter.additional-info')}
              </AdditionalInfoStyled>
              {/* <LabelStyled>
                <Field type="checkbox" name="toggle" />
                <p>
                  I want to receive news about products or services, news and
                  promotions regarding Hike Matters.
                </p>
              </LabelStyled> */}

              <ButtonStyled
                type="submit"
                disabled={!formik.isValid || !formik.dirty}
              >
                {t('button.register')}
              </ButtonStyled>
            </FormStyled>
          );
        }}
      </Formik>
      {showNotification && (
        <Notification
          message={t('message.success')}
          show={true}
          type="success"
        />
      )}
      {showErrorNotification && (
        <Notification message={errorMessage} show={true} type="error" />
      )}
    </FormContainer>
  );
};
