import styled from 'styled-components';
import tatryImage from '../../../../assets/images/mountains.webp';
import baticImage from '../../../../assets/images/malaga.webp';

interface HikingContainerProps {
  background: 'tatry' | 'malaga';
}

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100vw;
  height: 100%;
  position: relative;
`;

export const ContentContainer = styled.div<HikingContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 70%;
  margin: 50px auto;
  gap: 30px;
  background-image: ${(props) =>
    props.background === 'tatry' ? `url(${tatryImage})` : `url(${baticImage})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 840px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
`;

export const UpcommingTreksContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 70px;
`;

export const Text = styled.p`
  color: white;
  font-weight: 800;
  line-height: 2em;
  letter-spacing: 0.05rem;
  text-align: center;
  width: 60%;
  @media (max-width: 840px) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const Title = styled.h1`
  color: white;
  font-weight: 900;
  font-size: 4.5em;
  line-height: 1.5em;
  letter-spacing: 0.2rem;
  margin: 0 15px 50px 15px;
  @media (max-width: 1200px) {
    font-size: 3em;
  }
  @media (max-width: 840px) {
    font-size: 2.5em;
  }
`;

export const Subtitle = styled.h2`
  margin-bottom: 30px;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.1rem;
  font-size: 1.6em;
  margin: 0 15px;
`;

export const ButtonStyled = styled.button`
  width: 50%;
  margin-top: 30px;
  font-weight: 900;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 3px;
  box-shadow: 5px 5px 30px 1px rgba(0, 0, 0, 0.2);
  transition:
    background-color 0.1s ease,
    transform 0.1s ease;

  &:hover {
    background-color: #af9483;
  }
  &:focus {
    background-color: #af9483;
  }
  &:disabled {
    background-color: rgba(189, 189, 189, 0.7);
    color: #e5e5e5;
    box-shadow: none;
  }
`;
