export const images = [
  '/images/tatry.webp',
  '/images/tatry1.webp',
  '/images/tatry6.webp',
  '/images/tatry3.webp',
  '/images/tatry4.webp',
  '/images/tatry2.webp',
  '/images/tatry5.webp',
  '/images/tatry7.webp',
  '/images/tatry14.webp',
  '/images/tatry8.webp',
  '/images/tatry10.webp',
  '/images/tatry11.webp',
  '/images/tatry9.webp',
  '/images/tatry13.webp',
  '/images/tatry12.webp',
  '/images/tatry15.webp',
];

export const imagesMalaga = [
  '/images/malaga3.webp',
  '/images/malaga5.webp',
  '/images/malaga7.webp',
  '/images/malaga8.webp',
  '/images/malaga9.webp',
  '/images/malaga6.webp',
  '/images/malaga10.webp',
  '/images/malaga1.webp',
];

// export const imagesMalaga = [
//   '/images/malaga3.webp',
//   '/images/malaga5.webp',
//   '/images/malaga7.webp',
//   '/images/malaga8.webp',
//   '/images/malaga9.webp',
//   '/images/malaga6.webp',
//   '/images/malaga10.webp',
//   '/images/malaga1.webp',
// ];
