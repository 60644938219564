import React, { useLayoutEffect } from 'react';
import {
  Content,
  ContentContainer,
  PageContainer,
  Title,
  BoldText,
  Text,
  StyledSubtitle,
  StyledList,
  SmallText,
} from './Hikes.styles';
import { useTranslation } from 'react-i18next';
import PhotoGalleryMalaga from './PhotoGalleryMalaga';

export const Malaga = () => {
  const { t } = useTranslation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const listItems1 = t('malaga.list1', { returnObjects: true }) as string[];
  return (
    <PageContainer>
      <ContentContainer>
        <Content>
          <Title>{t('hiking.malaga')}</Title>
          <BoldText>{t('hiking.destination-malaga')}</BoldText>
          <BoldText>{t('hiking.date-malaga')}</BoldText>
          <Text> {t('malaga.description0')}</Text>
          <StyledSubtitle>{t('malaga.subtitle2')}</StyledSubtitle>
          <Text>{t('malaga.description')}</Text>
          <StyledSubtitle>{t('malaga.subtitle3')}</StyledSubtitle>
          <Text>{t('malaga.description1')}</Text>
          <StyledList>
            {listItems1.map((item: string, index: number) => (
              <li key={index}>
                <Text>{item}</Text>
              </li>
            ))}
          </StyledList>
          <StyledSubtitle>{t('malaga.joinUs')}</StyledSubtitle>
          <Text>{t('malaga.description2')}</Text>
          <Text>{t('malaga.description3')}</Text>
          <StyledSubtitle>{t('malaga.howToJoinUs')}</StyledSubtitle>
          <Text>{t('malaga.description4')}</Text>
          <Text>{t('malaga.description5')}</Text>
          <Text>{t('malaga.description6')}</Text>
          <PhotoGalleryMalaga />
          <SmallText>{t('malaga.description7')}</SmallText>
        </Content>
      </ContentContainer>
    </PageContainer>
  );
};
